import { Box, Button, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import React, { useContext, useState } from 'react';
import MealPlanContext from './MealPlanContext';

function AddRecipeBox({ modalOpen, toggleModal }) {
    const [recipeUrl, setRecipeUrl] = useState('');
    const [itemList, setItemList] = useState('');
    const [aiDescription, setAiDescription] = useState('');

    const { addRecipeUrl, addRecipeList } = useContext(MealPlanContext);

    const handleSubmitURL = (url) => {
        addRecipeUrl(url)
        if (modalOpen) {
            toggleModal()
        }
    }

    const handleSubmitItemList = (itemList) => {
        addRecipeList(itemList)
        if (modalOpen) {
            toggleModal()
        }
    }

    const handleSubmitAiDescription = (description) => {
        console.log(description)
    }

    return (
        <>
            <Box sx={{  display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
            <Box sx={{ width: 0.3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: '2em' }} >
                <Box>
                    <Typography variant='h6'>Fra hjemmeside</Typography>
                    <Typography variant='subtitle2'>Indsæt opskriftens webadresse:</Typography>
                </Box>
                <TextField
                    label='https://eksempel.dk/opskrift'
                    size="medium"
                    variant="outlined"
                    value={recipeUrl}
                    onChange={(e) => setRecipeUrl(e.target.value)}
                />
                <Button variant="contained" onClick={() => handleSubmitURL(recipeUrl)}>Hent ingredienser</Button>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ width: 0.3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: '2em' }} >
                <Box>
                    <Typography variant='h6'>Din opskrift</Typography>
                    <Typography variant='body2'>Tilføj din egen liste med ingredienser eller indkøb. </Typography>
                </Box>
                <TextField
                    label="2 liter mælk..."
                    multiline
                    rows={4}
                    value={itemList}
                    onChange={(e) => setItemList(e.target.value)}
                />
                <Button variant="contained"  onClick={() => handleSubmitItemList(itemList)}>Tilføj</Button>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ width: 0.3, display: 'flex', flexDirection: 'column', margin: '2em' }} >
                <Box>
                    <Typography variant='h6'>Udforsk</Typography>
                    <Typography variant='body2'>Søg blandt alle opskrifterne i vores bibliotek. </Typography>
                </Box>
                <Button variant="contained"  onClick={() => handleSubmitAiDescription(aiDescription)}>Udforsk!</Button>
            </Box>
        </Box>
        </>
    );
}

export default AddRecipeBox;
