import React from 'react';

const AuthContext = React.createContext({
    isLoggedIn: false,
    accessToken: null,
    refreshToken: null,
    loginError: null,
    getUser: () => { },
    login: () => { },
    logout: () => { },
    refreshAccessToken: () => { },
    signup: () => { },
    signupError: null,
});

export default AuthContext;