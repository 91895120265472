import { Alert, Button, Divider, Link, Modal, Paper, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';

import AuthContext from './AuthContext';


const SignupAndLoginModal = ({ isOpen, setIsOpen }) => {
    const [view, setView] = useState('signup')
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { signup, signupError, login, loginError } = useContext(AuthContext);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const updateView = (newView) => {
        setView(newView)
    }

    const handleSignup = async (event) => {
        event.preventDefault();
        // Simulate login logic here
        console.log('Username:', username);
        console.log('Password:', password);
        const credentials = { username, email, password };
        console.log("signing up")
        const status = await signup(credentials);
        console.log(status)
        if (status) {
            setUsername('');
            setEmail('');
            setPassword('');
            handleClose()
        }
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        // Simulate login logic here
        console.log('Username:', username);
        console.log('Password:', password);
        const credentials = { username, password };
        const status = await login(credentials);
        if (status) {
            setUsername('');
            setPassword('');
            handleClose();
        }
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
        >
            <Paper sx={modalStyle}>
            {view === 'signup' && (
                <>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        Signup
                    </Typography>
                    {signupError && (
                            Object.entries(signupError).map(([field, errorMessages]) => (
                                    <div key={field}>
                                            {errorMessages.map((errorMessage) => (
                                                <Alert key={errorMessage} sx={{ mb: 2, width: '300px' }} severity="error">{field}: {errorMessage}</Alert>
                                            ))}
                                    </div>
                                ))
                    )}
                    <TextField
                        label="Username"
                        variant="outlined"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        sx={{ mb: 2, width: '300px' }}
                        required
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ mb: 2, width: '300px' }}
                        required
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{ mb: 2, width: '300px' }}
                        required
                    />
                    <Button onClick={handleSignup} variant="contained" color="primary">
                        Login
                    </Button>
                    <Divider sx={{ mt: 5 }} />
                    <Typography sx={{ mb: 2, mt: 2 }}>
                        Already have a user? <Link onClick={() => updateView("login")} href="#" onC>Login here.</Link>
                    </Typography>
                    </>
            )}
            {view === 'login' && (
                <>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        Login
                    </Typography>
                    {loginError && (
                        <Alert sx={{ mb: 2, width: '300px' }} severity="error">{loginError}</Alert>
                    )}
                    <TextField
                        label="Username"
                        variant="outlined"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        sx={{ mb: 2, width: '300px' }}
                        required
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{ mb: 2, width: '300px' }}
                        required
                    />
                    <Button onClick={handleLogin} variant="contained" color="primary">
                        Login
                    </Button>

                    <Divider sx={{ mt: 5 }} />
                    <Typography sx={{ mb: 2, mt: 2 }}>
                        Don't have a user? <Link onClick={() => updateView("signup")} href="#">Signup here.</Link>
                    </Typography>
                </>
                )}
            </Paper>
        </Modal >
    );
};
// { view === 'signup' && <SignupForm updateView={updateView} closeModal={handleClose} /> }
// { view === 'login' && <LoginForm updateView={updateView} closeModal={handleClose} /> }


export default SignupAndLoginModal;
