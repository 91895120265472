import Cookies from 'js-cookie';
import { useEffect, useMemo, useState } from 'react';
import AuthContext from './AuthContext';

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(Cookies.get('user') || null);
    const [accessToken, setAccessToken] = useState(Cookies.get('accessToken') || null);
    const [refreshToken, setRefreshToken] = useState(Cookies.get('refreshToken') || null);
    const [loginError, setLoginError] = useState(null);
    const [signupError, setSignupError] = useState(null);

    // Function to determine if user is logged in
    const isLoggedIn = useMemo(() => !!refreshToken, [refreshToken]);

    // Login function
    const login = async (credentials) => {
        console.log("Logging in");
        console.log(credentials);

        try {
            const response = await fetch('http://localhost:8000/api/auth/jwt/create/', {
                method: 'POST',
                body: JSON.stringify(credentials),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                if (response.status === 401) {
                    setLoginError('Unauthorized login attempt');
                } else {
                    throw new Error('Network response was not ok');
                }
                return false;
            } else {
                const data = await response.json();
                setAccessToken(data.access);
                setRefreshToken(data.refresh);
                Cookies.set('accessToken', data.access, { expires: 1 }); // Cookie expires in 1 day
                Cookies.set('refreshToken', data.refresh, { expires: 7 }); // Cookie expires in 7 days
                setLoginError(null);
                return true;
            }
        } catch (error) {
            console.error('Error:', error);
            logout();
            return false;
        }
    };

    const getUser = async () => {
        if (user) {
            return user;
        }

        try {
            const response = await fetch('http://localhost:8000/api/auth/users/me/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                if (response.status === 401) {
                    setLoginError('Unauthorized login attempt');
                } else {
                    throw new Error('Network response was not ok');
                }
                return false;
            } else {
                const data = await response.json();
                setUser(data);
                Cookies.set('user', data, { expires: 7 }); // Cookie expires in 1 day
                return data;
            }
        } catch (error) {
            console.error('Error:', error);
            logout();
            return false;
        }
    };

    // Signup function
    const signup = async (credentials) => {
        console.log("Signing up");
        console.log(credentials);

        try {
            const response = await fetch('http://localhost:8000/api/auth/users/', {
                method: 'POST',
                body: JSON.stringify(credentials),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            console.log(data);

            if (!response.ok) {
                setSignupError(data);
                return false;
            } else {
                return await login(credentials);
            }
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
    };

    // Logout function
    const logout = () => {
        setAccessToken(null);
        setRefreshToken(null);
        Cookies.remove('accessToken');
        Cookies.remove('refreshToken');
        setLoginError(null);
    };

    // Function to refresh access token using refresh token
    const refreshAccessToken = async () => {
        console.log("Refreshing token");
        try {
            const response = await fetch('http://localhost:8000/api/auth/jwt/refresh/', {
                method: 'POST',
                body: JSON.stringify({ refresh: refreshToken }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setAccessToken(data.access);
            Cookies.set('accessToken', data.access, { expires: 1 });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Use useEffect to set up interval for refreshing token
    useEffect(() => {
        if (refreshToken) {
            const intervalId = setInterval(() => {
                refreshAccessToken();
            }, (5 * 60 - 15) * 1000); // 4 minutes and 45 seconds in milliseconds

            return () => clearInterval(intervalId);
        }
    }, [refreshToken]);

    const contextValue = {
        isLoggedIn,
        accessToken,
        refreshToken,
        getUser,
        login,
        loginError,
        logout,
        refreshAccessToken,
        signup,
        signupError,
    };

    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

export default AuthProvider;
