import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import AddRecipeBox from './AddRecipeBox';

function AddRecipeModal({ open, toggle, recipes, setRecipes }) {

    return (
      <Dialog open={open} onClose={toggle}>
        <DialogContent>
          <AddRecipeBox modalOpen={open} toggleModal={toggle} recipes={recipes} setRecipes={setRecipes} />
        </DialogContent>
    </Dialog>
    );
}

export default AddRecipeModal;
