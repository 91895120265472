import React, { useEffect, useState } from 'react';
import Ingredient from './ingredient';

function getIngredientsByCategory(ingredientsObj) {

    // Check if ingredients is an array
    // if (!Array.isArray(ingredients)) {
    //     throw new Error("Input must be an array of ingredients");
    // }

    let ingredients = Object.values(ingredientsObj);

    // Create a Map to store categories and ingredients
    const ingredientsByCategory = new Map();

    console.log(ingredients)
    // Loop through each ingredient
    for (const ingredient of ingredients) {
        // Split the ingredient string (optional: customize separator)

        // Assuming the first word is the category (adjust based on format)
        const category = ingredient.category;

        // Create or update the ingredient list for the category
        if (ingredientsByCategory.has(category)) {
            ingredientsByCategory.get(category).push(ingredient);
        } else {
            ingredientsByCategory.set(category, [ingredient]);
        }
    }

    // Return the Map containing ingredients grouped by category
    return Object.fromEntries(ingredientsByCategory);
}

function IngredientList({ingredients, view}) {
    const [expanded, setExpanded] = useState(false);
    const [categories, setCategories] = useState({});
    const [display, setDisplay] = useState('none')

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        setCategories(getIngredientsByCategory(ingredients))
    }, [ingredients, view])

    useEffect(() => {
        if (view === 'shopping') {
            setDisplay('')
        }
        else {
            setDisplay('none')
        }
    }, [view])

    return (
        <div style={{ display: display }}>
            {Object.keys(categories).map((category) => (
                <div key={category}>
                    <p>{category}</p>
                    <>
                        {categories[category].map((ingredient, index) => (
                            <Ingredient key={index} ingredient={ingredient} expanded={expanded} handleChange={handleChange} />
                        ))}
                    </>
                </div>
            ))}
        </div>
    );
}

export default IngredientList;