import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

const IngredientCarousel = ({ ingredients }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [activeIngredients, setActiveIngredients] = useState();

    const handleScroll = (scrollDirection) => {
        const newPosition = scrollPosition + scrollDirection;
        if (newPosition >= 0 && newPosition <= ingredients.length - 5) {
            setScrollPosition(newPosition);
        }
    };

    const addToCart = (ingredient) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ product_id: ingredient.id, username: 'asger.soerensen@live.com', password: "3568aSer" })
        };
        fetch('http://localhost:8000/api/ingredients/vendor/nemlig/basket/add/', requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => console.log(data))//setProducts(data))
            .catch((error) => console.error('Error:', error));
    }


    if (ingredients.length === 0) {
        // Render alternative content when the ingredients array is empty
        return <div className="ingredient-carousel empty">No ingredients available.</div>;
    }

    const dimOnTrue = (flag) => {
        return {
            opacity: flag ? 0.15 : 1,
        }
    }
    // <img class="ingredient-carousel-img" style={{ width: "50px" }} src={ingredient.image} alt={ingredient.name} />

    return (
        <div>
            <Stack direction="row" spacing={2}>
                <Box sx={{width: "25px"}}>
                {scrollPosition > 0 &&
                    <NavigateBeforeIcon fontSize="large" onClick={() => handleScroll(-1)} />
                }
                </Box>
                {ingredients.slice(scrollPosition, scrollPosition+5).map((ingredient, index) => (
                    <Card key={index} style={{ opacity: ingredient.is_available ? 1 : 0.15 }} sx={{
                        maxWidth: 175,
                        margin: "0 auto",
                        padding: "0.1em",
                    }}
                    >
                        <CardMedia component="img" image={ingredient.image} title={ingredient.name} sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }} />
                        <CardContent>
                            <Typography variant="subtitle1">
                                {ingredient.name}
                            </Typography>
                            <Typography variant="subtitle2">
                                {ingredient.description}
                            </Typography>
                            <Typography variant="body2">
                                {ingredient.price} kr
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <ShoppingCartOutlinedIcon size="small" onClick={() => addToCart(ingredient)} />
                        </CardActions>
                    </Card>
                ))}
                <Box sx={{ width: "25px" }}>
                {scrollPosition + 5 < ingredients.length &&
                    <NavigateNextIcon fontSize="large" onClick={() => handleScroll(1)} />
                }
                </Box>
            </Stack>
        </div>
    );
};

export default IngredientCarousel;
