import { DialogTitle, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React from 'react';

function RecipeModal({ recipe, open, setOpen }) {

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
            <DialogTitle>{recipe.name}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography>{recipe.url}</Typography><br />
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ width: 1 / 2 }}>
                            <Typography>
                                Ingredienser:
                                {(recipe.recipe_ingredients.length > 0) && recipe.recipe_ingredients.map((ingredient, index) => (
                                    <li key={index}>{ingredient.amount} {ingredient.unit} {ingredient.name} ({ingredient.ingredient.name})</li>
                                ))}
                            </Typography>
                        </Box>
                        <Box sx={{ width: 1 / 2 }}>
                            Opskriftstrin:<br />
                            { (recipe.steps && (recipe.steps.length > 0)) ? (
                                <ol>
                                    {recipe.steps.map((step, index) => (
                                    <li key={index}>{step}</li>
                                ))}
                                    </ol>
                            )
                        : <></>}
                        </Box>
                    </Box>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

export default RecipeModal;
