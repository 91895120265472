import React, { useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import IngredientCarousel from './IngredientCarousel';

function Ingredient({ ingredient, expanded, handleChange}) {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetch('http://localhost:8000/api/ingredients/vendor/nemlig/search/?query=' + ingredient.name + '&take=10')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => setProducts(data))//setProducts(data))
            .catch((error) => console.error('Error:', error));
    }, [ingredient]);

    return (
        <Accordion expanded={expanded === ingredient.name} onChange={handleChange(ingredient.name)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={ingredient.name + "-content"}
                id={ingredient.name + "-header"}
            >
                {ingredient.name}
            </AccordionSummary>
            <AccordionDetails>
                {ingredient.recipe_ingredients.map((recipe_ingredient, index) => (
                    <div key={index}>{recipe_ingredient.recipe.name}: {recipe_ingredient.amount} {recipe_ingredient.unit} {recipe_ingredient.name}</div>
                ))}
                {products.length > 0 ? ( // Check if 'products' array is not empty
                    <IngredientCarousel ingredients={products} />
                ) : (
                    <p>No ingredients available.</p> // Render a message when 'products' is empty
                )}
            </AccordionDetails>
        </Accordion>

    );
}

export default Ingredient;