import axios from 'axios';
import Cookies from 'js-cookie';
import { useContext, useEffect, useState } from 'react';
import AuthContext from './AuthContext';
import MealPlanContext from './MealPlanContext';

const MealPlanProvider = ({ children }) => {
    const [mealPlan, setMealPlan] = useState(Cookies.get('mealPlan') || null);
    const [recipes, setRecipes] = useState(Cookies.get('recipes') || []);
    const [ingredients, setIngredients] = useState(Cookies.get('ingredients') || []);

    const { isLoggedIn, accessToken, getUser } = useContext(AuthContext);

    const createMealPlan = async () => {
        try {
            const response = await axios.post(
                'http://localhost:8000/api/meal-plan/',
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: isLoggedIn ? `Bearer ${accessToken}` : null,
                    },
                }
            );

            if (response.status === 201) {
                const data = response.data;
                setMealPlan(data.id);
                Cookies.set('mealPlan', data.id, { expires: 7 });
            } else {
                if (response.status === 401) {
                    throw new Error('Unauthorized login attempt');
                } else {
                    throw new Error('Network response was not ok');
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const getMealPlan = async () => {
        if (mealPlan) {
            return mealPlan;
        }

        // If logged in, try to retrieve the latest meal-plan
        if (isLoggedIn) {
            try {
                const response = await axios.get(
                    'http://localhost:8000/api/meal-plan/latest/',
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

                if (response.status === 200) {
                    const data = response.data;
                    setMealPlan(data.id);
                    Cookies.set('mealPlan', data.id, { expires: 7 });
                    getRecipes();
                    getIngredients();
                    return data.id;
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }

        // if none, create a new plan
        await createMealPlan();
        return mealPlan;
    };

    const addRecipeUrl = async (url) => {
        const currentMealPlan = await getMealPlan();
        if (!currentMealPlan) return;

        try {
            const response = await axios.post(
                `http://localhost:8000/api/meal-plan/${currentMealPlan}/add-recipe-url/`,
                { url },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.status === 200) {
                setRecipes([...recipes, response.data]);
                Cookies.set('recipes', [...recipes, response.data], { expires: 7 });
                getIngredients();
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const addRecipeList = async (recipeList) => {
        const currentMealPlan = await getMealPlan();
        if (!currentMealPlan) return;

        try {
            const response = await axios.post(
                `http://localhost:8000/api/meal-plan/${currentMealPlan}/add-recipe-list/`,
                { recipes: recipeList },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.status === 200) {
                setRecipes([...recipes, ...response.data]);
                Cookies.set('recipes', [...recipes, ...response.data], { expires: 7 });
                getIngredients();
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const getRecipes = async () => {
        if (recipes.length > 0) {
            return recipes;
        }

        const currentMealPlan = await getMealPlan();
        if (!currentMealPlan) return;

        try {
            const response = await axios.get(`http://localhost:8000/api/meal-plan/${currentMealPlan}/recipes/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                setRecipes(response.data);
                Cookies.set('recipes', response.data, { expires: 7 });
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const getIngredients = async () => {
        const currentMealPlan = await getMealPlan();
        if (!currentMealPlan) return;

        try {
            const response = await axios.get(`http://localhost:8000/api/meal-plan/${currentMealPlan}/ingredients/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                setIngredients(response.data);
                Cookies.set('ingredients', response.data, { expires: 7 });
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const switchMealPlan = async (newMealPlanId) => {
        try {
            const response = await axios.get(`http://localhost:8000/api/meal-plan/${newMealPlanId}/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                const data = response.data;
                setMealPlan(newMealPlanId);
                Cookies.set('mealPlan', newMealPlanId, { expires: 7 });
                setRecipes([]);
                setIngredients([]);

                // Fetch and update recipes and ingredients for the new meal plan
                getRecipes();
                getIngredients();

                setIngredients(data.ingredients);
                Cookies.set('ingredients', data.ingredients, { expires: 7 });
            }
        } catch (error) {
            console.error('Error switching meal plan:', error);
        }
    };

    const removeRecipe = async (recipeId) => {
        const currentMealPlan = await getMealPlan();
        if (!currentMealPlan) return;

        try {
            const response = await axios.delete(
                `http://localhost:8000/api/meal-plan/${currentMealPlan}/remove-recipe/${recipeId}/`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.status === 200) {
                const updatedRecipes = recipes.filter(recipe => recipe.id !== recipeId);
                setRecipes(updatedRecipes);
                Cookies.set('recipes', updatedRecipes, { expires: 7 });
                getIngredients();
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const clearRecipes = async () => {
        const currentMealPlan = await getMealPlan();
        if (!currentMealPlan) return;

        try {
            const response = await axios.delete(
                `http://localhost:8000/api/meal-plan/${currentMealPlan}/clear-recipes/`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.status === 200) {
                setRecipes([]);
                Cookies.set('recipes', [], { expires: 7 });
                getIngredients();
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        const updateMealPlanWithUserId = async () => {
            if (isLoggedIn && mealPlan) {
                const user = await getUser();
                try {
                    await axios.patch(
                        `http://localhost:8000/api/meal-plan/${mealPlan}/`,
                        { created_by: user.id },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    );
                } catch (error) {
                    console.error('Error updating meal plan with user ID:', error);
                }
            }

            if (!isLoggedIn) {
                setMealPlan(null);
                setRecipes([]);
                setIngredients([]);
            }
        };

        updateMealPlanWithUserId();
    }, [isLoggedIn]);

    const contextValue = {
        mealPlan,
        recipes,
        ingredients,
        createMealPlan,
        getMealPlan,
        addRecipeUrl,
        addRecipeList,
        removeRecipe,
        clearRecipes,
        getRecipes,
        getIngredients,
        switchMealPlan,
    };

    return (
        <MealPlanContext.Provider value={contextValue}>{children}</MealPlanContext.Provider>
    );
};

export default MealPlanProvider;
