import React from 'react';

const MealPlanContext = React.createContext({
    mealPlan: null,
    recipes: [],
    ingredients: [],
    createMealPlan: () => { },
    getMealPlan: () => { },
    addRecipeUrl: () => { },
    addRecipeList: () => { },
    getRecipes: () => { },
    removeRecipe: () => { },
    clearRecipes: () => { },
    getIngredients: () => { },
    switchMealPlan: () => { },
});

export default MealPlanContext;