import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import TextTransition, { presets } from 'react-text-transition';

import AddRecipeContainer from './AddRecipeContainer';
import AddRecipeModal from './AddRecipeModal';
import AuthContext from './AuthContext';
import IngredientList from './IngredientList';
import MealPlanContext from './MealPlanContext';
import RecipeList from './RecipeList';
import SignupAndLoginModal from './SignupModal';

const TEXTS = ['måltidsplan', 'familiemiddag', 'festmenu', 'firmamiddag', 'fredagsmenu', 'vennemiddag', 'middagsmenu'];

const App = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [signupModalIsOpen, setSignupModalIsOpen] = useState(false);
  // const [recipes, setRecipes] = useState([]);
  const [ingredients, setIngredients] = useState({});
  const [view, setView] = useState('recipe');
  const [textIndex, setTextIndex] = useState(0);

  const { recipes, createMealPlan, clearRecipes } = useContext(MealPlanContext);
  const { isLoggedIn, logout } = useContext(AuthContext);

  const toggleView = () => {
    setView(view === 'recipe' ? 'shopping' : 'recipe');
  };

  const toggleAddRecipeModal = () => {
    setModalOpen(!modalOpen)
  }

  // useEffect(() => {
  //   setIngredients(convert_recipes_to_ingredient_centric(recipes))
  // }, [recipes])

  // const handleSubmitURL = async (recipeUrl) => {
  //   console.log('handleSubmit called with URL:', recipeUrl);
  //   try {
  //     console.log('Sending request to /api/recipes/');
  //     const response = await axios.post('http://localhost:8000/api/recipes/extract/', { url: recipeUrl });
  //     console.log('Received response:', response.data);
  //     const newRecipe = response.data;
  //     if (recipes.length > 0) {
  //       setRecipes([...recipes, newRecipe]);
  //     }
  //     else {
  //       setRecipes([newRecipe])
  //     }
  //     console.log(newRecipe)
  //   } catch (error) {
  //     console.error('Error while fetching recipe:', error);
  //   }
  // }

  // const handleSubmitItemList = async (itemList) => {
  //   console.log('Item list called with:', itemList);
  // }

  // const handleSubmitAiDescription = async (aiDescription) => {
  //   console.log('AI generation called with:', aiDescription);
  //   try {
  //     console.log('Sending request to /api/recipes/');
  //     const response = await axios.post('http://localhost:8000/api/recipes/generate/', { description: aiDescription });
  //     console.log('Received response:', response.data);
  //     const newRecipe = response.data;
  //     if (recipes.length > 0) {
  //       setRecipes([...recipes, newRecipe]);
  //     }
  //     else {
  //       setRecipes([newRecipe])
  //     }
  //     console.log(newRecipe)
  //   } catch (error) {
  //     console.error('Error while fetching recipe:', error);
  //   }
  // }

  // const clearRecipes = async () => {
  //   setRecipes([])
  // }

  const toggleLogin = () => {
    setSignupModalIsOpen(true)
  }

  const toggleLogout = () => {
    logout()
  }

  useEffect(() => {
    const intervalId = setInterval(
      () => setTextIndex((textIndex) => textIndex + 1),
      3000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div>
      <CssBaseline />
      <Container>
        <Box sx={{ p: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="button" display="block" gutterBottom>Umami</Typography>
          {isLoggedIn ? (
            <Button variant="outlined" onClick={toggleLogout}>
              Logout
            </Button>
          ) : (
            <Box>
              <Button sx={{ mr: 1 }} variant="outlined" onClick={toggleLogin}>
                Sign up
              </Button>
              <Button variant="contained" onClick={toggleLogin}>
                Login
              </Button>
              <SignupAndLoginModal isOpen={signupModalIsOpen} setIsOpen={setSignupModalIsOpen} />
            </Box>
          )}
        </Box>
        <Divider variant="middle" />
        {recipes.length === 0 && (
          <Box>
            <Typography variant="h1" component="h3" sx={{ display: 'flex', flexDirection: 'row', marginTop: '1em', marginBottom: '0.5em' }} gutterBottom>
              <Box sx={{ pl: '1.5em' }}>Skab din&nbsp;</Box>
              <Box><TextTransition inline={true} springConfig={presets.wobbly}> {TEXTS[textIndex % TEXTS.length]} </TextTransition></Box>
            </Typography>
            <AddRecipeContainer />
          </Box>
        )}
        {recipes.length > 0 && (
          <Box>
            <Box sx={{ p: 3, display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Button variant="contained" onClick={toggleView}>
                  Gem
                </Button>
                <Button sx={{ ml: 1 }} variant="contained" onClick={toggleView}>
                  {view === 'recipe' ? 'Se indkøbsliste' : 'Se opskrifter'}
                </Button>
              </Box>
              <Button variant="contained" onClick={clearRecipes}>
                Nulstil opskrifter
              </Button>
            </Box>
            <AddRecipeModal open={modalOpen} toggle={toggleAddRecipeModal} />
            <RecipeList view={view} recipes={recipes} ingredients={ingredients} setIngredients={setIngredients} toggleAddRecipeModal={toggleAddRecipeModal} />
            <IngredientList view={view} ingredients={ingredients} />
          </Box>
        )}
      </Container>
    </div >
  );
};

export default App;
