import AddIcon from '@mui/icons-material/Add';
import { Card, CardActionArea, CardContent } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';

import ItemListRecipeComponent from './ItemListRecipeComponent';
import WebRecipeComponent from './WebRecipeComponent';


export default function RecipeList({ view, recipes, ingredients, setIngredients, toggleAddRecipeModal }) {
    const [display, setDisplay] = useState('none')

    useEffect(() => {
        if (view === 'recipe') {
            setDisplay('')
        }
        else {
            setDisplay('none')
        }
    }, [view])

    const getRecipeComponentByType = (recipe) => {
        switch (recipe.type) {
            case 'url':
                return <WebRecipeComponent key={recipe.id} url={recipe.url} ingredients={ingredients} setIngredients={setIngredients} />
            case 'itemList':
                return <ItemListRecipeComponent key={recipe.id} itemList={recipe.itemList} ingredients={ingredients}  setIngredients={setIngredients} />
        }
    }
    return (
        <div style={{display: display}}>
            <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
            {
                recipes.map(getRecipeComponentByType)
            }
            <Card sx={{ width: '30%', m: 2 }}>
                <CardActionArea onClick={toggleAddRecipeModal} sx={{ height: 1 }}>
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center' }}>
                        <AddIcon /><Box>Tilføj opskrift!</Box>
                    </CardContent>
                </CardActionArea>

            </Card>
        </Box>
        </div>
    )
}