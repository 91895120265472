import { Box } from '@mui/material';
import React from 'react';

import AddRecipeBox from './AddRecipeBox';

function AddRecipeContainer() {

    return (
        <Box >
            <AddRecipeBox />
        </Box>
    );
}

export default AddRecipeContainer;
