import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useEffect, useState } from 'react';
import RecipeModal from './RecipeModal';

export default function ItemListRecipeComponent({ itemList, ingredients, setIngredients }) {
    const [modalOpen, setModalOpen] = useState(false);
    const [fetchedData, setFetchedData] = useState(null);

    useEffect(() => {
        fetchData(itemList)
    }, [itemList]);

    const fetchData = async (itemList) => {
        console.log('handleSubmit called with:', itemList);
        try {
            const response = await axios.post('http://localhost:8000/api/recipes/extract/', { itemList: itemList });
            const recipeData = response.data;
            setFetchedData(recipeData);
        } catch (error) {
            console.error('Error while fetching recipe:', error);
        }
    }


    const updateIngredients = () => {
        // Use the provided ingredients object, assuming it's already initialized outside
        fetchedData.recipe_ingredients.forEach((ingredient) => {
            console.log(ingredient)
            const pk = ingredient.ingredient.pk;

            if (!(pk in ingredients)) { // Use the provided ingredients object
                ingredients[pk] = {
                    pk: pk,
                    name: ingredient.ingredient.name,
                    category: ingredient.ingredient.category,
                    recipe_ingredients: [],
                };
            }

            ingredients[pk].recipe_ingredients.push({
                id: ingredient.id,
                name: ingredient.name,
                amount: ingredient.amount,
                unit: ingredient.unit,
                description: ingredient.description,
                recipe: {
                    name: fetchedData.name, // Use fetchedData.name here
                },
            });
        });

        setIngredients(ingredients); // Update the state using setIngredients
    };

    // Call updateIngredients when fetchedData is available
    useEffect(() => {
        if (fetchedData) {
            updateIngredients();
        }
    }, [fetchedData]);

    return (
        <>
            {fetchedData ? (
                <>
                    <Card sx={{ width: '30%', m: 2 }}>
                        <CardActionArea onClick={() => setModalOpen(true)} sx={{height: 1}}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {fetchedData.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {fetchedData.url}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <RecipeModal recipe={fetchedData} open={modalOpen} setOpen={setModalOpen} />
                </>
            ) : (
                <Card sx={{ width: '30%', m: 2 }}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Loading data...
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {itemList}
                        </Typography>
                    </CardContent>
                </Card>
            )}
        </>
    );
}